import React from 'react';
import './App.scss';
import 'semantic-ui-less/semantic.less';
import Header from "./components/Header";
import Top from "./components/Top";
import Content from "./components/Content";
import {BrowserRouter} from "react-router-dom";
import "./i18n";
import Footer from "./components/Footer";
import Announce from "./components/Announce";

function App() {
  return (
    <React.Suspense fallback={<div>Loading... </div>}>
      <BrowserRouter>
        <Announce/>
        <Top/>
        <Header />
        <Content />
        <Footer />
      </BrowserRouter>
    </React.Suspense>
  );
}

export default App;
