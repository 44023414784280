import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_G_ANALYTICS_UA);
ReactGA.pageview(window.location.pathname + window.location.search);

ReactGA4.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<div>Loading... </div>}>
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
