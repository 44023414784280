import React from 'react'
import {Container, Grid} from "semantic-ui-react";
import {useTranslation, Trans} from "react-i18next";

export default function Footer () {
  const { t } = useTranslation(["general"]);

  return (
    <footer>
      <br/>
      <br/>
      <br/>
      <br/>
      <Container id="Footer">
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column width={5}>
              <address>
                Words of Magic<br/>
                65 rue de Reuilly<br/>
                75012 Paris FRANCE
              </address>
            </Grid.Column>
            <Grid.Column id="central-column" width={6} textAlign="center" verticalAlign='middle'>
              <a target="_blank" rel="noreferrer" id="Contact" href="mailto:contact@wordsofmagic.com">CONTACT</a>
              &nbsp;-&nbsp;
              <a target="_blank" rel="noreferrer" id="Twitter" href="https://twitter.com/WoM_Fr">TWITTER</a>
            </Grid.Column>
            <Grid.Column width={5} textAlign="right">
              <small style={{float: "right"}}><Trans>{t("footer-legal")}</Trans></small>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </footer>
  )
};
