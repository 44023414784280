import React from "react";
import {useTranslation} from "react-i18next";
import {Dropdown} from 'semantic-ui-react'
import {NavLink, useRouteMatch} from 'react-router-dom';

export default function LanguageSelector() {
  const { i18n } = useTranslation(["header"]);
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  let match = useRouteMatch("/:lang/:page");
  let lang = match ? match.params.lang : i18n.language

  let selectorIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><path fill="#fff" d="M64,22a43,43,0,1,0,43,43A43,43,0,0,0,64,22ZM95.46,88a48.19,48.19,0,0,0-9-4.1A70.48,70.48,0,0,0,88.9,67h14A38.75,38.75,0,0,1,95.46,88ZM25.05,67H39.1a70.48,70.48,0,0,0,2.43,16.91,48.19,48.19,0,0,0-9,4.1A38.75,38.75,0,0,1,25.05,67Zm7.49-25a48.19,48.19,0,0,0,9,4.1A70.48,70.48,0,0,0,39.1,63h-14A38.75,38.75,0,0,1,32.54,42ZM66,49.62a71.52,71.52,0,0,0,16.55-2.34A65.13,65.13,0,0,1,84.9,63H66Zm0-4V26.19C72.3,27.37,77.89,34,81.33,43.47A67.1,67.1,0,0,1,66,45.62ZM62,26.19V45.62a67.1,67.1,0,0,1-15.33-2.15C50.11,34,55.7,27.37,62,26.19Zm0,23.43V63H43.1a65.13,65.13,0,0,1,2.35-15.72A71.52,71.52,0,0,0,62,49.62ZM43.1,67H62V80.38a71.52,71.52,0,0,0-16.55,2.34A65.13,65.13,0,0,1,43.1,67ZM62,84.38v19.43C55.7,102.63,50.11,96,46.67,86.53A67.1,67.1,0,0,1,62,84.38Zm4,19.43V84.38a67.1,67.1,0,0,1,15.33,2.15C77.89,96,72.3,102.63,66,103.81Zm0-23.43V67H84.9a65.13,65.13,0,0,1-2.35,15.72A71.52,71.52,0,0,0,66,80.38ZM88.9,63a70.48,70.48,0,0,0-2.43-16.91,48.19,48.19,0,0,0,9-4.1A38.75,38.75,0,0,1,103,63Zm4-24.15a44.18,44.18,0,0,1-7.64,3.43,39.39,39.39,0,0,0-8.12-14A39.06,39.06,0,0,1,92.91,38.85ZM50.85,28.29a39.39,39.39,0,0,0-8.12,14,44.18,44.18,0,0,1-7.64-3.43A39.06,39.06,0,0,1,50.85,28.29ZM35.09,91.15a44.18,44.18,0,0,1,7.64-3.43,39.39,39.39,0,0,0,8.12,14A39.06,39.06,0,0,1,35.09,91.15Zm42.06,10.56a39.39,39.39,0,0,0,8.12-14,44.18,44.18,0,0,1,7.64,3.43A39.06,39.06,0,0,1,77.15,101.71Z"/><path fill="#fff" d="M64,0A64,64,0,0,0,8.26,95.43l-8.19,30A2,2,0,0,0,2,128a1.79,1.79,0,0,0,.51-.07l30.39-8A64,64,0,1,0,64,0Zm0,124a60,60,0,0,1-29.83-8,2,2,0,0,0-1-.26,2.22,2.22,0,0,0-.51.06L4.84,123.18l7.49-27.5a2,2,0,0,0-.2-1.53A60,60,0,1,1,64,124Z"/></svg>
  let flagFR = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 2"><path fill="#ED2939" d="M0 0h3v2H0z"/><path fill="#fff" d="M0 0h2v2H0z"/><path fill="#002395" d="M0 0h1v2H0z"/></svg>
  let flagUK = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30"><clipPath id="a"><path d="M0 0v30h60V0z"/></clipPath><clipPath id="b"><path d="M30 15h30v15zv15H0zH0V0zV0h30z"/></clipPath><g clipPath="url(#a)"><path d="M0 0v30h60V0z" fill="#012169"/><path d="M0 0l60 30m0-30L0 30" stroke="#fff" strokeWidth="6"/><path d="M0 0l60 30m0-30L0 30" stroke="#C8102E" strokeWidth="4"/><path d="M30 0v30M0 15h60" stroke="#fff" strokeWidth="10"/><path d="M30 0v30M0 15h60" stroke="#C8102E" strokeWidth="6"/></g></svg>

  return (
    <Dropdown item openOnFocus closeOnBlur closeOnEscape closeOnChange icon={selectorIcon} text={lang === "fr" ? "Français" : "English"} >
      <Dropdown.Menu>
        <NavLink to={"/fr/" + (match ? match.params.page : '')} onClick={() => changeLanguage("fr")}>
          <Dropdown.Item icon={flagFR} text="Français"/>
        </NavLink>
        <NavLink to={"/en/" + (match ? match.params.page : '')} onClick={() => changeLanguage("en")}>
          <Dropdown.Item icon={flagUK} text="English"/>
        </NavLink>
      </Dropdown.Menu>
    </Dropdown>
  );
}
