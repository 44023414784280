import React from 'react';
import {useEffect} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {Container, Menu, Tab} from "semantic-ui-react";
import Recent from "./Games/Recent";
import Old1 from "./Games/Old1";
import Old2 from "./Games/Old2";
import './Games.scss'
import {seo} from "../../helpers";

function Games() {
  const { t } = useTranslation(["page-games", "header"]);

  useEffect(() => {
    seo({title: t("header:games")});
  }, [t]);

  let panes = [
    { menuItem: t('tab-recent'), render: () => <Tab.Pane><Recent /></Tab.Pane> },
    { menuItem:
        <Menu.Item key="old-1">
          <label className="widescreen">{t('tab-old-1')}</label>
          <label className="smallscreen">{t('tab-old-abreviated-1')}</label>
        </Menu.Item>,
      render: () => <Tab.Pane><Old1 /></Tab.Pane> },
    { menuItem:
        <Menu.Item key="old-2">
          <label className="widescreen">{t('tab-old-2')}</label>
          <label className="smallscreen">{t('tab-old-abreviated-2')}</label>
        </Menu.Item>,
      render: () => <Tab.Pane><Old2 /></Tab.Pane> },
  ];

  return (
    <Container id={"Games"}>
      <br/>
      <br/>
      <Container text textAlign='center'><Trans t={t}>{t('intro')}</Trans></Container>
      <br/>
      <br/>
      <Tab panes={panes} />
    </Container>
  );
}

export default Games;
