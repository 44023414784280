import React from 'react';
import {Container} from "semantic-ui-react";
import {Redirect, Route, Switch} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Games from "./pages/Games";
import Home from "./pages/Home";
import Philosophy from "./pages/Philosophy";
import Services from "./pages/Services";
import Network from "./pages/Network";

function Content() {
  return (
    <Container id={"Content"}>
      <Switch>
        <Route path="/:lang/games"><Games/></Route>
        <Route path="/:lang/services"><Services/></Route>
        <Route path="/:lang/network"><Network/></Route>
        <Route path="/:lang/who-are-we"><Philosophy/></Route>
        <Route exact path="/"><RedirectToDefaultLanguage/></Route>
        <Route path="/:lang"><Home/></Route>
      </Switch>
    </Container>
  )
}

function RedirectToDefaultLanguage() {
  const {i18n} = useTranslation();
  return <Redirect to={"/" + i18n.language + "/"}/>;
}

export default Content;
