import React from 'react';
import { Item } from 'semantic-ui-react'
import {useTranslation} from "react-i18next";

function Recent() {
  const { t } = useTranslation(["page-games", "general"]);

  const items = [
    {
      childKey: 5,
      image: '/Games/GLBurger.png',
      header: 'Godlike Burger',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.spanish) / $t(general:language.latam) / $t(general:language.portuguese-brazil) / $t(general:language.polish)'})}</p>,
      meta: '2021',
    },
    {
      childKey: 6,
      image: '/Games/SyberiaTWB.png',
      header: 'Syberia - The World Before',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.spanish) / $t(general:language.italian) / $t(general:language.portuguese-brazil)'})}</p>,
      meta: '2021',
    },
    {
      childKey: 7,
      image: '/Games/Vertigo.png',
      header: 'Alfred Hitchcock Vertigo',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.spanish) / $t(general:language.italian)'})}</p>,
      meta: '2021',
    },
    {
      childKey: 8,
      image: '/Games/SpireOfSorcery.png',
      header: 'Spire of Sorcery',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2020 - 2021',
    },
    {
      childKey: 10,
      image: '/Games/SKPocket.png',
      header: 'Shovel Knight Pocket Dungeon',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.korean) / $t(general:language.spanish) / $t(general:language.italian) / $t(general:language.portuguese-brazil) / $t(general:language.russian) / $t(general:language.simplified-chinese) / $t(general:language.traditional-chinese)'})}</p>,
      meta: '2021',
    },
    {
      childKey: 11,
      image: '/Games/STAiko.png',
      header: 'Shadow Tactics - Aiko\'s Choice',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.spanish) / $t(general:language.portuguese-brazil) / $t(general:language.polish)'})}</p>,
      meta: '2021',
    },
    {
      childKey: 12,
      image: '/Games/GKBetterSaveSoul.png',
      header: 'Graveyard Keeper - Better Save Soul',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2021',
    },
    {
      childKey: 13,
      image: '/Games/SlenderThreads.png',
      header: 'Slender Threads - Prologue',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2021',
    },
    {
      childKey: 14,
      image: '/Games/FlingToTheFinish.png',
      header: 'Fling To The Finish',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.spanish) / $t(general:language.latam) / $t(general:language.portuguese-brazil) / $t(general:language.polish)'})}</p>,
      meta: '2021',
    },
    {
      childKey: 15,
      image: '/Games/Glitchpunk.png',
      header: 'Glitchpunk',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2021',
    },
    {
      childKey: 16,
      image: '/Games/YouFirst.png',
      header: 'You First',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.italian) / $t(general:language.portuguese-brazil)'})}</p>,
      meta: '2021',
    },
    {
      childKey: 17,
      image: '/Games/PartisansBackToBattle.png',
      header: 'Cyber Partisans 1941 - Back To Battle',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.korean) / $t(general:language.spanish) / $t(general:language.latam) / $t(general:language.polish)'})}</p>,
      meta: '2021',
    },
    {
      childKey: 18,
      image: '/Games/CyberShadow.png',
      header: 'Cyber Shadow',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.korean) / $t(general:language.spanish) / $t(general:language.italian) / $t(general:language.portuguese-brazil) / $t(general:language.russian) / $t(general:language.simplified-chinese) / $t(general:language.traditional-chinese)'})}</p>,
      meta: '2021',
    },
	  {
      childKey: 19,
      image: '/Games/RebelGalaxyOutlaw.png',
      header: 'Rebel Galaxy Outlaw',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2019 - 2020',
    },
    {
      childKey: 20,
      image: '/Games/Partisans1941.png',
      header: 'Partisans 1941',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.korean) / $t(general:language.spanish) / $t(general:language.latam) / $t(general:language.polish)'})}</p>,
      meta: '2020',
    },
    {
      childKey: 30,
      image: '/Games/ShovelKnightKingOfCard.png',
      header: 'Shovel Knight: King of Cards',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.spanish) / $t(general:language.italian) / $t(general:language.portuguese-brazil) / $t(general:language.russian)'})}</p>,
      meta: '2019 - 2020',
    },
    {
      childKey: 40,
      image: '/Games/CryoFall.png',
      header: 'CryoFall',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.spanish) / $t(general:language.polish) / $t(general:language.portuguese-brazil)'})}</p>,
      meta: '2019 - 2020',
    },
    {
      childKey: 50,
      image: '/Games/Unrailed.png',
      header: 'Unrailed!',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.spanish) / $t(general:language.polish) / $t(general:language.portuguese-brazil)'})}</p>,
      meta: '2019 - 2020',
    },
    {
      childKey: 60,
      image: '/Games/DiceyDungeons.png',
      header: 'Dicey Dungeons',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2019',
    },
    {
      childKey: 70,
      image: '/Games/Blacksad.png',
      header: 'Blacksad',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.italian) / $t(general:language.dutch)'})}</p>,
      meta: '2019',
    },
    {
      childKey: 80,
      image: '/Games/Draugen.png',
      header: 'Draugen',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.japanese) / $t(general:language.russian) / $t(general:language.simplified-chinese) / $t(general:language.spanish)'})}</p>,
      meta: '2019',
    },
    {
      childKey: 90,
      image: '/Games/SwagAndSorcery.png',
      header: 'Swag & Sorcery',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2019',
    },
    {
      childKey: 100,
      image: '/Games/TrainValley2.png',
      header: 'Train Valley 2',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.portuguese-brazil) / $t(general:language.italian) / $t(general:language.spanish)'})}</p>,
      meta: '2019',
    },
    {
      childKey: 110,
      image: '/Games/Nobodies.png',
      header: 'Nobodies',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2019',
    },
    {
      childKey: 120,
      image: '/Games/FelixTheReaper.png',
      header: 'Felix The Reaper',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.portuguese-brazil) / $t(general:language.polish) / $t(general:language.spanish)'})}</p>,
      meta: '2019',
    },
    {
      childKey: 130,
      image: '/Games/Eastshade.png',
      header: 'Eastshade',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2019',
    },
    {
      childKey: 140,
      image: '/Games/RebelCops.png',
      header: 'Rebel Cops',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2019',
    },
    {
      childKey: 150,
      image: '/Games/ObraDinn.png',
      header: 'Return of the Obra Dinn',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2018',
    },
    {
      childKey: 160,
      image: '/Games/GraveyardKeeper.png',
      header: 'Graveyard Keeper',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2018',
    },
    {
      childKey: 170,
      image: '/Games/TheFallPart2Unbound.png',
      header: 'The Fall Part 2: Unbound',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.spanish) / $t(general:language.italian)'})}</p>,
      meta: '2018',
    },
	  {
      childKey: 180,
      image: '/Games/StateOfMind.png',
      header: 'State of Mind',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.spanish) / $t(general:language.portuguese-brazil)'})}</p>,
      meta: '2018',
    },
  ]
  let orderedItems = items.sort((a, b) => a.childKey > b.childKey);

  return (
    <Item.Group items={orderedItems} relaxed divided />
  );
}

export default Recent;
