import React from 'react'
import {Container} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

export default function Announce () {
  const { t } = useTranslation(["general"]);

  return (
    <Container id={"Announce"}>
      {t('notification')}
    </Container>
  )
};
