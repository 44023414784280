import React from 'react';
import { Item } from 'semantic-ui-react'
import {useTranslation} from "react-i18next";

function Old2() {
  const { t } = useTranslation(["page-games", "general"]);

  const items = [
	  {
      childKey: 40,
      image: '/Games/RunawayTheDreamOfTheTurtle.png',
      header: 'Runaway The Dream Of The Turtle',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}</p>,
      meta: '2007',
    },
	  {
      childKey: 45,
      image: '/Games/CallOfJuarez.png',
      header: 'Call of Juarez',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}</p>,
      meta: '2007',
    },
	  {
      childKey: 50,
      image: '/Games/DarkMessiahOfMightAndMagic.png',
      header: 'Dark Messiah of Might & Magic',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2007',
    },
	  {
      childKey: 60,
      image: '/Games/HeroesOfMightAndMagicVTribesOfTheEast.png',
      header: 'Heroes of Might & Magic V: Tribes of the East',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2007',
    },
	  {
      childKey: 70,
      image: '/Games/HeroesOfMightAndMagicVHammersOfFate.png',
      header: 'Heroes of Might & Magic V: Hammers of Fate',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2006',
    },
	  {
      childKey: 80,
      image: '/Games/HeroesOfMightAndMagicV.png',
      header: 'Heroes of Might & Magic V',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2006',
    },
	  {
      childKey: 90,
      image: '/Games/BlackAndWhite2.png',
      header: 'Black & White 2',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2005',
    },
	  {
      childKey: 100,
      image: '/Games/TheLordOfTheRingsTheThirdAge.png',
      header: 'The Lord of the Rings: The Third Age',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2004',
    },
	  {
      childKey: 105,
      image: '/Games/RTXRedRock.png',
      header: 'RTX Red Rock',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}<br/></p>,
      meta: '2003',
    },
	  {
      childKey: 110,
      image: '/Games/TheLordOfTheRingsTheReturnOfTheKing.png',
      header: 'The Lord of the Rings: The Return of the King',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2003',
    },
	  {
      childKey: 120,
      image: '/Games/RunawayARoadAdventure.png',
      header: 'Runaway A Road Adventure',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}</p>,
      meta: '2003',
    },
	  {
      childKey: 130,
      image: '/Games/007Nightfire.png',
      header: '007 Nightfire',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2002',
    },
	  {
      childKey: 140,
      image: '/Games/TheLordOfTheRingsTheTwoTowers.png',
      header: 'The Lord of the Rings: The Two Towers',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2002',
    },
	  {
      childKey: 150,
      image: '/Games/ArxFatalis.png',
      header: 'Arx Fatalis',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}</p>,
      meta: '2002',
    },
	  {
      childKey: 160,
      image: '/Games/StarWarsBountyHunter.png',
      header: 'Star Wars: Bounty Hunter',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2002',
    },
	  {
      childKey: 170,
      image: '/Games/007AgentUnderFire.png',
      header: '007 Agent Under Fire',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2001',
    },
	  {
      childKey: 180,
      image: '/Games/BlackAndWhite.png',
      header: 'Black & White',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2001',
    },
	  {
      childKey: 190,
      image: '/Games/FalloutTactics.png',
      header: 'Fallout Tactics',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}</p>,
      meta: '2001',
    },
	  {
      childKey: 200,
      image: '/Games/StarWarsStarfighter.png',
      header: 'Star Wars: Starfighter',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2001',
    },
	  {
      childKey: 210,
      image: '/Games/AmericanMcGeesAlice.png',
      header: 'American McGee\'s Alice',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2000',
    },
	  {
      childKey: 220,
      image: '/Games/GiantsCitizenKabuto.png',
      header: 'Giants Citizen Kabuto',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}</p>,
      meta: '2000',
    },
	  {
      childKey: 230,
      image: '/Games/MDK2.png',
      header: 'MDK 2',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}</p>,
      meta: '2000',
    },
	{
      childKey: 235,
    image: '/Games/Nox.png',
      header: 'Nox',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2000',
    },
	  {
      childKey: 240,
      image: '/Games/StarWarsForceCommander.png',
      header: 'Star Wars: Force Commander',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2000',
    },
	  {
      childKey: 250,
      image: '/Games/StarWarsXWingAlliance.png',
      header: 'Star Wars: X-Wing Alliance',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '1999',
    },
	  {
      childKey: 260,
      image: '/Games/Caesar3.png',
      header: 'Caesar 3',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}</p>,
      meta: '1999',
    },
	  {
      childKey: 270,
      image: '/Games/LordsOfMagicSpecialEdition.png',
      header: 'Lords of Magic Special Edition',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '1998',
    },

  ]
  let orderedItems = items.sort((a, b) => a.childKey > b.childKey);

  return (
    <Item.Group items={orderedItems} relaxed divided />
  );
}

export default Old2;
