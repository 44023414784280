import React from 'react'
import {Container, Image} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Top () {
  const { i18n } = useTranslation(["header"]);
  return (
    <Container id={"Top"}>
      <NavLink to={"/"+i18n.language+"/"}>
        <Image style={{height: "10em", padding: "0.5em"}} centered src="/wom.png" />
      </NavLink>
    </Container>
  )
};
