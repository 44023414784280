import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Container, Grid, GridColumn, GridRow, Image} from "semantic-ui-react";
import {Link} from "react-router-dom";
import './Home.scss'
import {seo} from "../../helpers";

function Games() {
  const { t } = useTranslation(["page-home", "header"]);

  useEffect(() => {
    seo({title: ""});
  }, []);

  function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  let imageList = [
    "/Home/Blacksad.jpg",
    "/Home/BW1.png",
    "/Home/BW2.jpg",
    "/Home/Deponia4.jpg",
    "/Home/Dreamfall.png",
    "/Home/GraveyardKeeper.jpg",
    "/Home/HoMM5.png",
    "/Home/ObraDinn.jpg",
    "/Home/PapersPlease.png",
    "/Home/Runaway1.jpg",
    "/Home/Runaway2.jpg",
    "/Home/Runaway3.jpg",
    "/Home/ShadowTactics.png",
    "/Home/ShovelKnight.png",
    "/Home/ToMI.jpg",
    "/Home/XWA.png",
  ];
  imageList = shuffle(imageList);

  return (
    <Container id={"Home"}>
      <br/>
      <Grid textAlign='center'>
        <GridRow   columns='equal'>
          <GridColumn className="tdimension keepUnder1100 keepUnder800 keepUnder600 keepUnder450"><Link to={"games"} className="top"><Image src={imageList.pop()} /></Link></GridColumn>
          <GridColumn className="tdimension keepUnder1100 keepUnder800 keepUnder600 keepUnder450"><Link to={"games"} className="top"><Image src={imageList.pop()} /></Link></GridColumn>
          <GridColumn className="tdimension keepUnder1100 keepUnder800 keepUnder600"><Link to={"games"} className="top"><Image src={imageList.pop()} /></Link></GridColumn>
          <GridColumn className="tdimension keepUnder1100 keepUnder800"><Link to={"games"} className="top"><Image src={imageList.pop()} /></Link></GridColumn>
          <GridColumn className="tdimension keepUnder1100"><Link to={"games"} className="top"><Image src={imageList.pop()} /></Link></GridColumn>
          <GridColumn className="tdimension"><Link to={"games"} className="top"><Image src={imageList.pop()} /></Link></GridColumn>
        </GridRow>
        <GridRow columns='equal'>
          <GridColumn className="keepUnder1100 keepUnder800 keepUnder600 keepUnder450"><Link to={"games"}><Image src={imageList.pop()} centered /></Link></GridColumn>
          <GridColumn className="keepUnder1100"><Link to={"games"}><Image src={imageList.pop()} centered /></Link></GridColumn>
          <GridColumn className="keepUnder1100 keepUnder800" width={5} textAlign="center" verticalAlign='middle' id="leitmotiv">
            {t("leitmotiv-start")}
            <div id="flip">
              <div><div>{t("leitmotiv-part-1")}</div></div>
              <div><div>{t("leitmotiv-part-2")}</div></div>
              <div><div>{t("leitmotiv-part-3")}</div></div>
            </div>
            {t("leitmotiv-end")}
          </GridColumn>
          <GridColumn className="keepUnder1100"><Link to={"games"}><Image src={imageList.pop()} centered /></Link></GridColumn>
          <GridColumn className="keepUnder1100 keepUnder800 keepUnder600 keepUnder450"><Link to={"games"}><Image src={imageList.pop()} centered /></Link></GridColumn>
        </GridRow>
        <GridRow columns='equal'>
          <GridColumn className="tdimension keepUnder1100 keepUnder800 keepUnder600 keepUnder450"><Link to={"games"} className="bottom"><Image src={imageList.pop()} /></Link></GridColumn>
          <GridColumn className="tdimension keepUnder1100 keepUnder800 keepUnder600 keepUnder450"><Link to={"games"} className="bottom"><Image src={imageList.pop()} /></Link></GridColumn>
          <GridColumn className="tdimension keepUnder1100 keepUnder800 keepUnder600"><Link to={"games"} className="bottom"><Image src={imageList.pop()} /></Link></GridColumn>
          <GridColumn className="tdimension keepUnder1100 keepUnder800"><Link to={"games"} className="bottom"><Image src={imageList.pop()} /></Link></GridColumn>
          <GridColumn className="tdimension keepUnder1100"><Link to={"games"} className="bottom"><Image src={imageList.pop()} /></Link></GridColumn>
          <GridColumn className="tdimension"><Link to={"games"} className="bottom"><Image src={imageList.pop()} /></Link></GridColumn>
        </GridRow>
      </Grid>
    </Container>
  );
}

export default Games;
