import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import { Trans } from 'react-i18next'
import {Container, Divider, Grid, Image, Message} from "semantic-ui-react";
import './Philosophy.scss'
import {seo} from "../../helpers";

function Philosophy() {
  const { t } = useTranslation(["page-philosophy", "header"]);

  useEffect(() => {
    seo({title: t("header:who-are-we")});
  }, [t]);

  return (
    <Container id={"Philosophy"}>
      <br/>
      <Grid verticalAlign='middle' columns={3}>
        <Grid.Row columns={3} centered>
          <Grid.Column />
          <Grid.Column textAlign='center'><Message compact><Trans t={t}>{"logo-top"}</Trans></Message></Grid.Column>
          <Grid.Column />
        </Grid.Row>

        <Grid.Row columns={3}>
          <Grid.Column textAlign='right'><Message compact style={{textAlign: "center"}}><Trans t={t}>{"logo-left"}</Trans></Message></Grid.Column>
          <Grid.Column textAlign='center'><Image src={"/mana.png"} /></Grid.Column>
          <Grid.Column textAlign='left'><Message compact style={{textAlign: "center"}}><Trans t={t}>{"logo-right"}</Trans></Message></Grid.Column>
        </Grid.Row>

        <Grid.Row columns={3} centered>
          <Grid.Column />
          <Grid.Column textAlign='center'><Message compact><Trans t={t}>{"logo-bottom"}</Trans></Message></Grid.Column>
          <Grid.Column />
        </Grid.Row>
      </Grid>

      <br/>
      <Divider />
      <br/>

      <Container id={"PhilosophyTexts"}>
        <Image src='/Philosophy/CoktelSierra.png' size="small" floated='left' className={"leftOriented"} />
        <Container text textAlign='center'>{t("philosophy-text-1")}</Container>
        <br/>
        <Container text textAlign='center' className={"ContainerTextLeft"}>{t("philosophy-text-2")}</Container>
        <div id="photoDisplay" className={"ContainerTextLeft"}>
          <Image src="/Philosophy/adAtari.jpg" size="small" className="photos" id="samplePhoto1"/>
          <Image src="/Philosophy/adMo5.jpg" size="small" className="photos" id="samplePhoto2"/>
          <Image src="/Philosophy/adOric.png" size="small" className="photos" id="samplePhoto3"/>
        </div>
        <br/>
        <Image src='/Philosophy/LogoWIP.png' size="small" floated='left' />
        <Container text textAlign='center'>{t("philosophy-text-3")}</Container>
        <br/>
        <Container text textAlign='center' className={"ContainerTextLeft"}>{t("philosophy-text-4")}</Container>
        <Image src='/Philosophy/Studio.png' size="medium" floated='left' className={"rightOriented"} />
        <br/>
        <Container text textAlign='center' className={"ContainerTextRight"}>{t("philosophy-text-5")}</Container>
        <Image src='/Philosophy/WoM.jpg' size="medium" floated='right' />
        <br/>
        <Container text textAlign='center'>{t("philosophy-text-6")}</Container>
      </Container>

    </Container>
  );
}

export default Philosophy;
