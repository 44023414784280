import React from 'react'
import WomMenu from "./header/WomMenu";

export default function Header () {
  return (
    <header>
      <WomMenu/>
    </header>
  )
};
