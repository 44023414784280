import React from 'react';
import {Container, Menu} from 'semantic-ui-react';
import {useTranslation} from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import { NavLink} from "react-router-dom";

const WomMenu = () => {
  const { i18n, t } = useTranslation(["header"]);

  return (
    <Menu size='huge' widths={5} color={"mana"} inverted>
      <Container>
        <Menu.Item as={NavLink} to={"/"+i18n.language+"/games"} name='games'>{t("games")}</Menu.Item>
        <Menu.Item as={NavLink} to={"/"+i18n.language+"/services"} name='services'>{t("services")}</Menu.Item>
        <Menu.Item as={NavLink} to={"/"+i18n.language+"/who-are-we"} name='philosophy'>{t("who-are-we")}</Menu.Item>
        <Menu.Item as={NavLink} to={"/"+i18n.language+"/network"} name='network'>{t("network")}</Menu.Item>
        <LanguageSelector/>
      </Container>
    </Menu>
  )
};


export default WomMenu;
