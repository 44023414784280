import React, {useEffect, useState} from 'react';
import {useTranslation, Trans} from "react-i18next";
import {Container, Image} from "semantic-ui-react";
import './Services.scss'
import {seo} from "../../helpers";

function Services() {
  const { t } = useTranslation(["page-services", "header"]);
  const initialState = "";
  const [content, setContent] = useState(initialState);

  useEffect(() => {
    seo({title: t("header:services")});
  }, [t]);

  let toggleContent = (e, key) => {
    setContent(<Trans t={t}>{key}</Trans>);
  }

  let resetContent = () => {
    setContent(initialState);
  }

  let services = [
    { "key": "service-project-management-key", "title": t("service-project-management-title"), "content": "service-project-management-content", "className": "right" },
    { "key": "service-tests-key", "title": t("service-tests-title"), "content": "service-tests-content", "className": "right" },
    { "key": "service-experience-key", "title": t("service-experience-title"), "content": "service-experience-content", "className": "left" },
    { "key": "service-studio-key", "title": t("service-studio-title"), "content": "service-studio-content", "className": "left" },
    { "key": "service-translation-key", "title": t("service-translation-title"), "content": "service-translation-content", "className": "left" },
    { "key": "service-glossary-key", "title": t("service-glossary-title"), "content": "service-glossary-content", "className": "right" },
  ]

  return (
    <Container id={"Services"}>
      <Container id={"ExplanationText"} text textAlign={"center"}>{t("service-translation-help")}</Container>
      <Image id={"Mana"} src={"/mana.png"} centered/>
      <ul className='circle-container'>
      {Object.values(services).map(service => (
        <li id={service.key} key={service.key}>
          <div className={"service"} onMouseOver={(e) => toggleContent(e, service.content)} onMouseLeave={resetContent}>
            <span className={"title "+service.className}><Trans t={t}>{service.title}</Trans></span>
            <span className={"content"}><Trans t={t}>{service.content}</Trans></span>
          </div>
        </li>
      ))}
        <li className="value">{content}</li>
      </ul>
    </Container>
  );
}

export default Services;
