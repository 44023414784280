import React from 'react';
import { Item } from 'semantic-ui-react'
import {useTranslation} from "react-i18next";

function Old1() {
  const { t } = useTranslation(["page-games", "general"]);

  const items = [
    {
      childKey: 10,
      image: '/Games/ShovelKnightSpecterOfTorment.png',
      header: 'Shovel Knight: Specter of Torment',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.spanish) / $t(general:language.italian) / $t(general:language.portuguese-brazil) / $t(general:language.russian)'})}</p>,
      meta: '2017',
    },
    {
      image: '/Games/DreamfallChapters.png',
      childKey: 20,
      header: 'Dreamfall Chapters',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2014 - 2017',
    },
    {
      childKey: 30,
      image: '/Games/KenFollettsThePillarsOfTheEarth.png',
      header: 'Ken Follett\'s The Pillars of the Earth',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.spanish)'})}</p>,
      meta: '2017 - 2018',
    },
    {
      childKey: 32,
      image: '/Games/AERMemoriesOfOld.png',
      header: 'AER Memories of Old',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.spanish) / $t(general:language.polish) / $t(general:language.portuguese-brazil)'})}</p>,
      meta: '2017',
    },
	  {
      childKey: 34,
      image: '/Games/BountyTrain.png',
      header: 'Bounty Train',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.spanish)'})}</p>,
      meta: '2017',
    },
	{
      childKey: 35,
      image: '/Games/TheEyesOfAra.png',
      header: 'The Eyes of Ara',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2016',
    },
	  {
      childKey: 40,
      image: '/Games/DeponiaDoomsday.png',
      header: 'Deponia Doomsday',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.spanish) / $t(general:language.portuguese-brazil)'})}</p>,
      meta: '2016',
    },
    {
      childKey: 50,
      image: '/Games/PunchClub.png',
      header: 'Punch Club',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2016',
    },
    {
      childKey: 60,
      image: '/Games/GremlinsInc.png',
      header: 'Gremlins, Inc.',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2016',
    },
    {
      childKey: 70,
      image: '/Games/ShadowTacticsBladesOfTheShogun.png',
      header: 'Shadow Tactics: Blades of the Shogun',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.spanish) / $t(general:language.portuguese-brazil)'})}</p>,
      meta: '2016',
    },
    {
      childKey: 80,
      image: '/Games/Silence.png',
      header: 'Silence',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.spanish) / $t(general:language.portuguese-brazil)'})}</p>,
      meta: '2016',
    },
    {
      childKey: 90,
      image: '/Games/SaltAndSanctuary.png',
      header: 'Salt and Sanctuary',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2016',
    },
    {
      childKey: 100,
      image: '/Games/YesterdayOrigins.png',
      header: 'Yesterday Origins',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) /  $t(general:language.italian) / $t(general:language.dutch)'})}</p>,
      meta: '2016',
    },
    {
      childKey: 110,
      image: '/Games/SpaceRunGalaxy.png',
      header: 'Space Run Galaxy',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2016',
    },
    {
      childKey: 120,
      image: '/Games/KelvinAndTheInfamousMachine.png',
      header: 'Kelvin and the Infamous Machine',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2016',
    },
    {
      childKey: 130,
      image: '/Games/Candle.png',
      header: 'Candle',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.portuguese-brazil) / $t(general:language.russian)'})}</p>,
      meta: '2016',
    },
    {
      childKey: 140,
      image: '/Games/VictorVran.png',
      header: 'Victor Vran',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.spanish) / $t(general:language.portuguese-brazil) / $t(general:language.russian)'})}</p>,
      meta: '2015',
    },
    {
      childKey: 150,
      image: '/Games/TrainValley.png',
      header: 'Train Valley',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.spanish) / $t(general:language.italian) / $t(general:language.polish) / $t(general:language.portuguese-brazil)'})}</p>,
      meta: '2015',
    },
    {
      childKey: 160,
      image: '/Games/SatelliteReign.png',
      header: 'Satellite Reign',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2015',
    },
    {
      childKey: 170,
      image: '/Games/RebelGalaxy.png',
      header: 'Rebel Galaxy',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2015',
    },
	  {
      childKey: 180,
      image: '/Games/TheFall.png',
      header: 'The Fall',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2015',
    },
	  {
      childKey: 190,
      image: '/Games/Tengami.png',
      header: 'Tengami',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2015',
    },
    {
      childKey: 195,
      image: '/Games/DeadSynchronicity.png',
      header: 'Dead Synchronicity: Tomorrow Comes Today',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2015',
    },
	  {
      childKey: 195,
      image: '/Games/AnnasQuest.png',
      header: 'Anna\'s Quest',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.spanish)'})}</p>,
      meta: '2015',
    },
	  {
      childKey: 200,
      image: '/Games/Cradle.png',
      header: 'Cradle',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.spanish) / $t(general:language.italian)'})}</p>,
      meta: '2015',
    },
	  {
      childKey: 210,
      image: '/Games/SpaceRun.png',
      header: 'Space Run',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2014',
    },
	  {
      childKey: 220,
      image: '/Games/ShovelKnight.png',
      header: 'Shovel Knight: Shovel of Hope',
      description: '',
      content: <p>{t('french-translation')}<br/>{t('translation-coordination', {'languages': '$t(general:language.german) / $t(general:language.spanish) / $t(general:language.italian) / $t(general:language.portuguese-brazil) / $t(general:language.russian)'})}</p>,
      meta: '2014',
    },
	  {
      childKey: 225,
      image: '/Games/RandalsMonday.png',
      header: 'Randal\'s Monday',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2014',
    },
	  {
      childKey: 227,
      image: '/Games/1954Alcatraz.jpg',
      header: '1954 Alcatraz',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2014',
    },
	  {
      childKey: 230,
      image: '/Games/GoodbyeDeponia.png',
      header: 'Goodbye Deponia',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2013',
    },
	  {
      childKey: 240,
      image: '/Games/PapersPlease.png',
      header: 'Papers, Please',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2013',
    },
	  {
      childKey: 250,
      image: '/Games/Contrast.png',
      header: 'Contrast',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}</p>,
      meta: '2013',
    },
	  {
      childKey: 260,
      image: '/Games/ChaosOnDeponia.png',
      header: 'Chaos on Deponia',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2013',
    },
	  {
      childKey: 265,
      image: '/Games/Yesterday.png',
      header: 'Yesterday',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2012',
    },
	  {
      childKey: 270,
      image: '/Games/DefendersQuestValleyOfTheForgotten.png',
      header: 'Defender\'s Quest: Valley of the Forgotten',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2012',
    },
	  {
      childKey: 280,
      image: '/Games/TheNextBigThing.png',
      header: 'The Next Big Thing',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}</p>,
      meta: '2011',
    },
	  {
      childKey: 285,
      image: '/Games/MightAndMagicHeroesVI.png',
      header: 'Might & Magic: Heroes VI',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}</p>,
      meta: '2011',
    },
	  {
      childKey: 290,
      image: '/Games/GrayMatter.png',
      header: 'Gray Matter',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2010',
    },
	  {
      childKey: 300,
      image: '/Games/TalesOfMonkeyIsland.png',
      header: 'Tales of Monkey Island',
      description: '',
      content: <p>{t('french-translation')}</p>,
      meta: '2009',
    },
	  {
      childKey: 310,
      image: '/Games/RunawayATwistOfFate.png',
      header: 'Runaway A Twist of Fate',
      description: '',
      content: <p>{t('french-translation')} + {t('french-studio')}</p>,
      meta: '2009',
    },
	  {
      childKey: 320,
      image: '/Games/MightAndMagicClashOfHeroes.png',
      header: 'Might & Magic: Clash of Heroes',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2009',
    },
	  {
      childKey: 330,
      image: '/Games/AVampyreStory.png',
      header: 'A Vampyre Story',
      description: '',
      content: <p>{t('french-translation')}<br/></p>,
      meta: '2008',
    },
  ]
  let orderedItems = items.sort((a, b) => a.childKey > b.childKey);

  return (
    <Item.Group items={orderedItems} relaxed divided />
  );
}

export default Old1;
