import React, {useEffect} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {Container, Grid, Image, Item} from "semantic-ui-react";
import {seo} from "../../helpers";

function Network() {
  const {t} = useTranslation(["page-network", "header"]);

  useEffect(() => {
    seo({title: t("header:network")});
  }, [t]);

  const itemsCol1 = [
    {
      childKey: 0,
      image: <Image src='/Network/WP_Josue120.png' size="small" />,
      header: 'Josué Monchan (' + t('general:language.spanish') + ')',
      meta: t('worked-on-title'),
      description: 'Shovel Knight, Gremlins Inc, Papers Please, Obra Dinn...',
      extra: t('founding-member') + ' / ' + t('experience', {'years': "15"}),
    },
    {
      childKey: 1,
      image: <Image src='/Network/WP_Renata120.png' size="small" />,
      header: 'Renata Forteleoni (' + t('general:language.italian') + ')',
      meta: t('worked-on-title'),
      description: 'Shovel Knight, Blacksad, Train Valley, The Fall Part 2...',
      extra: t('founding-member') + ' / ' + t('experience', {'years': "16"}),
      // extra: t('also-part-of', {'network': "Lokalike"}),
    },
    {
      childKey: 2,
      image: <Image src='/Network/WP_Uliana120.png' size="small" />,
      header: 'Uliana Erofeeva (' + t('general:language.russian') + ')',
      meta: t('worked-on-title'),
      description: 'Shovel Knight, Cyber Shadow, Draugen, Victor Vran...',
      extra: t('member-since', {'datejoined': "2014"}) + ' / ' + t('experience', {'years': "14"}),
    },
    {
      childKey: 3,
      image: <Image src='/Network/WP_Michal120.png' size="small" />,
      header: 'Michał Tosza (' + t('general:language.polish') + ')',
      meta: t('worked-on-title'),
      description: 'Partisans 1941, CryoFall, Unrailed!...',
      extra: t('member-since', {'datejoined': "2015"}) + ' / ' + t('experience', {'years': "15"}),
    },
  ]

  const itemsCol2 = [
    {
      childKey: 0,
      image: <Image src='/Network/WP_Rolf120.png' size="small" />,
      header: 'Rolf Klischewski (' + t('general:language.german') + ')',
      meta: t('worked-on-title'),
      description: 'Shovel Knight, Gremlins Inc, Papers Please, Obra Dinn, Blacksad...',
      extra: t('founding-member') + ' / ' + t('experience', {'years': "20"}),
    },
    {
      childKey: 1,
      image: <Image src='/Network/WP_RamonAlba120.png' size="small" />,
      header: 'Ramón Mendez & Alba Calvo (' + t('general:language.spanish') + ')',
      meta: t('worked-on-title'),
      description: 'Cyber Shadow, Shadow Tactics, Draugen, Partisans 1941...',
      extra: t('founding-member') + ' / ' + t('experience', {'years': "20"}),
    },
    {
      childKey: 2,
      image: <Image src='/Network/WP_Thiago120.png' size="small" />,
      header: 'Thiago Araujo (' + t('general:language.portuguese-brazil') + ')',
      meta: t('worked-on-title'),
      description: 'Shovel Knight, Cyber Shadow, State of Mind, Shadow Tactics...',
      extra: t('member-since', {'datejoined': "2015"}) + ' / ' + t('experience', {'years': "13"}),
    },
    {
      childKey: 3,
      image: <Image src='/Network/WP_Loek120.png' size="small" />,
      header: 'Loek van Kooten (' + t('general:language.japanese') + ' / ' + t('general:language.simplified-chinese') + ' / ' + t('general:language.traditional-chinese') + ')',
      meta: t('worked-on-title'),
      description: 'Cyber Shadow, Shovel Knight, Draugen...',
      extra: t('member-since', {'datejoined': "2015"}) + ' / ' + t('experience', {'years': "25"}),
    },
  ]

  return (
    <Container id={"Network"}>
      <br/>
      <br/>
      <Container text textAlign={"center"}><Trans t={t}>{t("introduction")}</Trans></Container>
      <br/>
      <br/>
      <Grid verticalAlign='middle' stackable columns={2} centered>
          <Grid.Column>
            <Item.Group items={itemsCol1}/>
          </Grid.Column>
          <Grid.Column>
            <Item.Group items={itemsCol2}/>
          </Grid.Column>
      </Grid>
    </Container>
  );
}

export default Network;
